<template>
  <v-card>
    <v-card-text>
      <v-card-text>
        <v-form ref="form">
          <p class="text-subtitle-1 text-center font-weight-light mb-2">Forgot Password?</p>
          <p class="text-caption mb-2">Enter the Registered Email</p>
          <v-text-field style="font-size: 1rem" outlined filled dense label="Email" v-model="resetEmail" :rules="mixinLoginEmailRule()" @keydown.space.prevent @keydown.enter.prevent="reset_password()"></v-text-field>

          <div class="text-caption-2">*Please enter the e-mail used for login/registration</div>
        </v-form>
      </v-card-text>
    </v-card-text>

    <v-card-actions class="d-flex justify-center mb-0 pb-0">
      <v-btn text color="#64B5F6" @click="back_to_login()">Back</v-btn>

      <v-spacer></v-spacer>
      <v-btn text depressed color="#64B5F6" @click="reset_password()">Next</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mixin_form_validation_utils } from "@/mixins/formValidationUtils";
import { mapGetters, mapMutations } from "vuex";

import { forgotPassword } from "@/services";
import { utilHandleExceptions } from "@/utils";

export default {
  mixins: [mixin_form_validation_utils],
  computed: {
    ...mapGetters(["getterGetWindowPage", "getterGetResetEmail"]),
    windowPage: {
      get() {
        return this.getterGetWindowPage;
      },
      set(value) {
        this.mutationSetWindowPage(value);
      },
    },
    resetEmail: {
      get() {
        return this.getterGetResetEmail;
      },
      set(value) {
        this.mutationSetResetEmail(value);
      },
    }
  },
  methods: {
    ...mapMutations(["mutationSetWindowPage", "mutationSetResetEmail", "mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    back_to_login() {
      this.resetEmail = "";
      this.windowPage = "Login";
    },
    async reset_password() {
      if (!this.$refs.form.validate()) {
        return this.triggerSnackBar("error", "Please enter a valid email address.");
      }

      try {
        await forgotPassword(this.resetEmail);
        this.windowPage = "Forgot-Password-Submit";
      } catch (error) {
        this.triggerSnackBar("error", utilHandleExceptions(error));
      }
    },
  },
};
</script>
