import { getSubscriptionList, getProductList, createStripeCustomer, createStripeSubscription, createCustomerPortalSession, deleteStripeCustomer } from "@/services";

const state = {
  subscriptionList: null,
  productList: null,
};

const getters = {
  // This is used to get the subscription list
  getterGetSubscriptionList: (state) => {
    return state.subscriptionList;
  },
  // This is used to get the product list
  getterGetProductList: (state) => {
    return state.productList;
  },
};

const mutations = {
  mutationSetSubscriptionList: (state, payload) => {
    state.subscriptionList = payload;
  },
  mutationSetProductList: (state, payload) => {
    state.productList = payload;
  },
};

const actions = {
    actionGetSubscriptionList: async (context) => {
        try {
            const result = await getSubscriptionList();
            context.commit('mutationSetSubscriptionList', result);
        } catch (err) {
            throw err
        }
    },
    actionGetProductList: async (context) => {
        try {
            const result = await getProductList();
            console.log(result);
            context.commit('mutationSetProductList', result);
        } catch (err) {
            throw err
        }
    },
    actionCreateCustomer: async (context, user_id) => {
        try {
            const result = await createStripeCustomer(user_id);
            return result;
        } catch (err) {
            throw err
        }
    },
    actionCreateSubscription: async (context, { routerId, stripeCustomerId, region }) => {
        try {
            const result = await createStripeSubscription(routerId, stripeCustomerId, region);
        } catch (err) {
            throw err
        }
    },
    actionCreateCustomerPortal: async (context, { email, routerModel, country }) => {
        try {
            const result = await createCustomerPortalSession(email, routerModel, country);
            return result;
        } catch (err) {
            throw err;
        }
    },
    actionDeleteCustomer: async (context, email) => {
        try {
            const result = await deleteStripeCustomer(email);
        } catch (err) {
            throw err
        }
    },
}

export default {
  state,
  getters,
  mutations,
  actions,
};
