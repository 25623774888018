<template>
  <v-card flat tile class="mb-4">
    <div class="py-4 mx-auto" style="max-width: var(--detail-dialog-max-width)">
      <div class="d-flex flex-row pl-1 pr-2" style="width: 100%">
        <div class="pa-0 pl-2">
          <div style="background-color: #E4E4E4; width: fit-content; height: fit-content; overflow:hidden"
            class="rounded-circle mt-4">
            <v-img style="background-color: #E4E4E4;" class="ma-4" :width="routerImageWidth" contain :aspect-ratio="1"
              :src="deviceImageSrc"></v-img>
          </div>
        </div>
        <div class="d-flex flex-column pl-4" style="width: 100%">
          <v-card-title class="mt-4 pa-0">
            <span>{{ routerName }}</span>
            <div class="d-flex ml-2"
              :style="{ 'color': onlineStatus === 'ONLINE' ? '#4CBB17' : '#D32F2F', 'font-size': '0.6rem' }">
              <v-icon style="color: inherit;" class="mr-1">mdi-{{ onlineStatus === "ONLINE" ? "wifi" : "wifi-off"
                }}</v-icon>
              <span class="d-none d-sm-inline">{{ onlineStatus }}</span>
            </div>
          </v-card-title>
          <v-card-text class="mb-2 pa-0 text-subtitle-1">
            {{ routerDescription }}
          </v-card-text>
          <v-card-subtitle class="pa-0 d-flex flex-row">
            <div class="d-flex flex-column" v-if="!isBusinessRouter">
              <span>Subscription: {{ licenseStatus }}</span>
              <span class="caption d-flex align-center">
                Ends on {{ licenseExpiration }} 
                <v-btn icon small @click="open_manage_subscription" color="#0195D2">
                  <v-icon small>
                    mdi-cog
                  </v-icon>
                </v-btn>
              </span>
            </div>
            <v-spacer></v-spacer>
            <v-btn
              class="text-none mr-4"
              outlined
              rounded
              color="#A2A2A9"
              @click="isEditingDeviceNameDesc = true"
            ><v-icon class="mx-2">mdi-pencil-outline</v-icon><span style="color: black">Edit Device</span></v-btn>
          </v-card-subtitle>
        </div>
      </div>
    </div>
    <EditDeviceNameDesc v-model:active="isEditingDeviceNameDesc" :initRouterName="routerName"
      :initRouterDescription="routerDescription" />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import EditDeviceNameDesc from "./SubDialogs/EditDeviceNameDesc.vue";

export default {
  components: {
    EditDeviceNameDesc,
  },
  data() {
    return {
      isEditingDeviceNameDesc: false,
      suspendInternetDialog: false,
    };
  },
  computed: {
    console: () => console,
    ...mapGetters(["getterGetDeviceConfigurations"]),
    // Device Image and Info
    deviceImageSrc() {
      return this.getterGetDeviceConfigurations.router_details.router_image ? this.getterGetDeviceConfigurations.router_details.router_image : "";
    },
    licenseStatus() {
      if (this.getterGetDeviceConfigurations.router_details.license_status.toLowerCase() === "trialing") return "Free Trial";
      if (this.getterGetDeviceConfigurations.router_details.license_status === "ACTIVE") return "Active";
      return "Canceled";
    },
    licenseExpiration() {
      const expString = this.getterGetDeviceConfigurations.router_details.expire_date;
      const dateObj = new Date(expString);
      return dateObj.toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"}) 
    },
    onlineStatus() {
      return this.getterGetDeviceConfigurations.router_details.online_status;
    },
    routerName() {
      return this.getterGetDeviceConfigurations ? this.getterGetDeviceConfigurations.router_details.router_name : "";
    },
    routerDescription() {
      return this.getterGetDeviceConfigurations ? this.getterGetDeviceConfigurations.router_details.router_description : "";
    },
    isBusinessRouter() {
      return this.getterGetDeviceConfigurations.router_details.is_individual_router === false;
    },
    routerImageWidth() {
      if (this.$vuetify.breakpoint.xs) return 60;
      else return 100;
    },
  },
  methods: {
    open_manage_subscription() {
      this.$parent.$parent.$parent.$parent.$parent.$emit('openManageSubscription');
    },
  },
};
</script>