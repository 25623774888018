import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardText,[_c(VCardText,[_c(VForm,{ref:"form"},[_c('p',{staticClass:"text-subtitle-1 text-center font-weight-light mb-2"},[_vm._v("Multi-Factor Authentication")]),_c(VTextField,{staticStyle:{"font-size":"1rem"},attrs:{"outlined":"","filled":"","dense":"","label":"Enter your 6 digit MFA token","maxlength":"6","rules":_vm.mixinMfaCodeRule()},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;$event.preventDefault();},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.mfa_login()}]},model:{value:(_vm.mfaCode),callback:function ($$v) {_vm.mfaCode=$$v},expression:"mfaCode"}})],1)],1)],1),_c(VCardActions,{staticClass:"d-flex justify-center mb-0 pb-0"},[_c(VBtn,{attrs:{"text":"","color":"#64B5F6","loading":_vm.loading},on:{"click":function($event){return _vm.back()}}},[_vm._v("Back")]),_c(VSpacer),_c(VBtn,{attrs:{"text":"","depressed":"","color":"#64B5F6","loading":_vm.loading},on:{"click":function($event){return _vm.mfa_login()}}},[_vm._v("Next")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }