<template>
  <div>
    <v-dialog v-model="manageSubscriptionDialog" fullscreen persistent hide-overlay transition="dialog-bottom-transition">
      <DialogCard :modalTitle="'Manage Subscriptions'" :modalIcon="'credit-card'">
        <template #header>
          <v-btn icon x-small @click="close_dialog()">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
        <template #body>
          <v-row class="mt-0 mb-4 mx-0 d-flex align-center">
            <h2>Subscription List</h2>
            <v-spacer></v-spacer>
            <v-btn class="button tabledata--text" @click="openCustomerPortal()" :loading="loadingCustomerPortal">Manage</v-btn>
          </v-row>
          <v-data-table dense :headers="headers" :search="search" :items="tableData" :items-per-page="10" :loading="isTableLoading" :no-data-text="isTableLoading ? 'Loading items...' : 'No devices available'" show-select item-key="router_id" class="data-table-border table-caption">
            <template v-slot:[`item.license_status`]="{ item }">
              <span :style="{ color: item.license_status === 'trialing' || item.license_status === 'ACTIVE' || item.license_status === 'active' ? 'green' : 'red'}">
                {{ capitalizeFirstLetter(item.license_status) }}
              </span>
            </template>
            <template v-slot:[`item.expire_date`]="{ item }">
              <span v-html="formatDateWithTime(item.expire_date)"></span>
            </template>
            <template v-slot:[`item.router_id`]="{ item }">
              {{ item.router_id.split("/")[1] }}
            </template>
          </v-data-table>
        </template>
      </DialogCard>
    </v-dialog>
  </div>
</template>
<script>
import DialogCard from "../Slots/DialogCard.vue";
import Styling from "/src/assets/Styling.css";
import { API, graphqlOperation } from "aws-amplify";
import { get_router_details_for_the_particular_user } from "@/graphql/queries.js";

import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  props: {
    manageSubscriptionDialog: Boolean,
  },
  components: {
    DialogCard,
    Styling,
  },
  data() {
    return {
      tableData: [],
      isTableLoading: false,
      loadingCustomerPortal: false,
      search: "",
      headers: [
        { text: "Status", align: "start", sortable: true, class: "headerColor white--text ", value: "license_status" },
        { text: "Subscription Name", align: "start", sortable: true, class: "headerColor white--text ", value: "license_name" },
        { text: "Device Name", align: "start", sortable: true, class: "headerColor white--text ", value: "router_name" },
        { text: "Renewal Date", sortable: true, class: "headerColor white--text ", value: "expire_date" },
        { text: "Model", sortable: true, class: "headerColor white--text ", value: "router_model" },
        { text: "Serial No.", sortable: true, class: "headerColor white--text ", value: "router_id" },
        // { text: "Manage", class: "headerColor white--text ", value: "actions" },
      ],
    };
  },
  watch: {
    async manageSubscriptionDialog(newVal) {
      if (newVal) {
        this.tableData = [];
        this.isTableLoading = true;
        var data = {};
        if (this.getterGetUserInfo.user.user_role == "G_M_ADMIN") {
          data = {
            user_id: this.getterGetUserInfo.user.user_id,
            router_group_id: this.getterGetUserInfo.user.router_group_id,
          };
        } else {
          data = {
            user_id: this.getterGetUserInfo.user.user_id,
          };
        }
        try {
          let result = await API.graphql(
            graphqlOperation(get_router_details_for_the_particular_user, {
              input: data,
            })
          );
          var response = JSON.parse(result.data.get_router_details_for_the_particular_user);
          this.tableData = response;
        } catch (err) {
          this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
          this.tableData = [];
        } finally {
          this.isTableLoading = false;
        }
      }
    },
  },
  computed: {
    ...mapGetters(["getterGetUserInfo"]),
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    ...mapActions(["actionCreateCustomerPortal"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    close_dialog() {
      this.$emit("close");
    },
    capitalizeFirstLetter(str) {
      //Remove once all devices convert from trialing to "Free Trial"
      if (str === "trialing") {
        return "Free Trial";
      }
      return str.toLowerCase().charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },
    //Alternate method that uses model to filter
    async openCustomerPortal() {
      try {
        this.loadingCustomerPortal = true;
        const email = this.getterGetUserInfo.user.user_email_id;
        const country = this.getterGetUserInfo.user.user_language_code === "en" ? "USA" : this.getterGetUserInfo.user.user_language_code === "ko" ? "Korea" : this.getterGetUserInfo.user.user_country_code;
        const model = [];
        this.tableData.forEach((subscription) => {
          model.push(subscription.router_model);
        });
        const response = await this.actionCreateCustomerPortal({
          email: email,
          routerModel: model,
          country: country,
        });
        this.loadingCustomerPortal = false;
        const session = await JSON.parse(response.data.create_customer_portal_session);
        window.location.href = session.url;
      } catch (err) {
        this.loadingCustomerPortal = false;
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      }
    },
    formatDateWithTime(dateString) {
      // Create a new Date object from the input date string
      const currentDate = new Date();
      const endDate = new Date(dateString);
      //Calculate days remaining
      const msDifference = endDate - currentDate;
      const millisecondsInADay = 86400000;
      const daysRemaining = Math.floor(msDifference / millisecondsInADay);
      // Extract the month, day, and year from the Date object
      const month = String(endDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so we add 1
      const day = String(endDate.getDate()).padStart(2, "0");
      const year = endDate.getFullYear();
      // Extract the hours, minutes, and seconds from the Date object
      const hours = String(endDate.getHours()).padStart(2, "0");
      const minutes = String(endDate.getMinutes()).padStart(2, "0");
      const seconds = String(endDate.getSeconds()).padStart(2, "0");

      // Return the formatted date string in MM/DD/YYYY HH:MM:SS format
      return `${month}/${day}/${year} ${hours}:${minutes}:${seconds} <i class="grey--text">(${daysRemaining} days remaining)</i>`;
    },
  },
};
</script>
