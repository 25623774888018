<template>
  <div>
    <v-layout>
      <div justify-center>
        <v-card class="mt-4 pt-2 justify-center">
          <v-card-title>Change Email</v-card-title>
          <v-card-text>
            <v-form ref="form">
              <div>Enter a new email address</div>
              <v-text-field dense v-model="email" class="mt-6" style="width: 400px" label="Email" :rules="mixinLoginEmailRule()" outlined @keyup.enter.prevent.stop="submit_email_change()"></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-end mr-2">
            <v-btn small depressed outlined class="blue--text mt-n4 ml-2 mb-2 normal-text" color="white" @click="previous_step()"> Cancel </v-btn>
            <v-btn small depressed class="button tabledata--text mt-n4 ml-2 mb-2 normal-text" :loading="loading" @click="submit_email_change()"> Change Email </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-layout>
  </div>
</template>

<script>
import { mixin_form_validation_utils } from '@/mixins/formValidationUtils.js';
import { mapGetters, mapMutations } from 'vuex';
import { updateCognitoUser } from '@/services'

export default {
  mixins: [ mixin_form_validation_utils ],
  data() {
    return {
      email: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters([ "getterGetUserInfo" ]),
    getCurrentUserID() {
      return this.getterGetUserInfo.user.user_id;
    },
    getCurrentUserCustomerID() {
      return this.getterGetUserInfo.user.customer_id;
    },
  },
  methods: {
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    //method invokes on cancel
    previous_step() {
      this.$emit("previousStep", 0);
    },
    //method invokes on success
    async submit_email_change() {
      let valid = this.$refs.form.validate();
      if(!valid) {
        this.triggerSnackBar("error", "Enter your new email in a proper format");
        return;
      }

      try {
        this.loading = true;
        const response = await updateCognitoUser({ email: this.email, userId: this.getCurrentUserID });
        if(response.code !== 0){
          throw new Error(response.message);
        }
        this.triggerSnackBar("success", "Your email has been changed successfully.");
        this.triggerSnackBar("notification", "Check your new email. A new temporary password has been sent.");
        this.$router.push("/");
      } catch(error) {
        this.triggerSnackBar("error", error);
      } finally {
        this.loading = false;
      }
    },

  },
};
</script>

<style>
.normal-text {
  text-transform: none;
}

.resend-code {
  position: absolute;
  left: 8px;
  top: 183px;
  letter-spacing: normal;
  color: #0195d2;
}
</style>
