export const edit_router_details = /* GraphQL */ `
  mutation Edit_router_details($input: EditRouterDetailsInput) {
    edit_router_details(input: $input)
  }
`;

export const resetRouter = /* GraphQL */ `
  mutation resetRouter($input: ResetRouterInput!) {
    resetRouter(input: $input) {
      message
      output {
        id
        result
      }
      reason {
        note
        id
      }
    }
  }
`;

export const delete_router_details = /* GraphQL */ `
  mutation Delete_router_details($input: DeleteRouterDetailsInput) {
    delete_router_details(input: $input)
  }
`;

export const reboot_router = /* GraphQL */ `
  mutation Reboot_router($input: RebootRouterInput) {
    reboot_router(input: $input)
  }
`;

export const update_configuration_of_router = /* GraphQL */ `
  mutation Update_configuration_of_router($input: UpdateConfigurationOfRouterInput) {
    update_configuration_of_router(input: $input)
  }
`;

export const update_router_groups_configuration = /* GraphQL */ `
  mutation Update_router_groups_configuration($input: UpdateRouterGroupsConfigurationInput) {
    update_router_groups_configuration(input: $input)
  }
`;
