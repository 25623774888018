<template>
  <v-app style="background-color: #e0e0e0">
    <v-main class="backgroundImg">
      <div class="align-center mt-6">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="6" align="center" justify="center">
            <v-card class="white mt-16" height="400">
              <div class="text-center pt-3 pb-3" style="font-size: 18px; color: black">
                <b v-if="componentCheck != 3">INDIVIDUAL SIGN UP</b>
                <b v-else>Review Information</b>
              </div>
              <v-window v-model="step">
                <v-window-item v-if="componentCheck == 0">
                  <v-form ref="form" v-model="valid3" lazy-validation>
                    <div class="text-center">
                      <h3 class="title font-weight-light black--text mb-2"></h3>
                      <div class="pt-6 pl-4 black--text" align="left" style="font-size: 12px"></div>
                      <v-card-text>
                        <AddDeviceInd :userDetails="userDetails" :userEmail="userEmail" :newPassword="newPassword" :deviceDetails="deviceDetails" :deviceDetailsEntered="deviceDetailsEntered" :device="device" @accDetails="acc_details" @cancelSignup="cancel_signup"></AddDeviceInd>
                      </v-card-text>
                    </div>
                  </v-form>
                </v-window-item>

                <v-window-item v-if="componentCheck == 1">
                  <v-form ref="form1" v-model="valid1" lazy-validation class="pa-0" @submit.prevent="validation_data">
                    <v-card-text>
                      <v-row class="mt-8" no-gutters>
                        <v-col>
                          <v-text-field dense v-model="userFirstName" label="First Name*" outlined maxlength="40" @keyup.enter="validation_data()" @keydown.space="prevent_leading_space" @input="set_limit(userFirstName)" v-on:keypress="specal_charaters($event)" v-on:paste="specal_charaters($event)" :rules="mixinPersonNameRule('first')"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field class="ml-2" dense v-model="userLastName" label="Last Name*" outlined maxlength="40" @keyup.enter="validation_data()" @input="set_limit(userLastName)" @keydown.space="prevent_leading_space" v-on:keypress="specal_charaters($event)" v-on:paste="specal_charaters($event)" :rules="mixinPersonNameRule('last')"></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row no-gutters>
                        <v-col cols="6">
                          <v-text-field label="Email*" dense v-model="userEmail" outlined @keyup.enter="validation_data()" @keydown.space="prevent_leading_space" :rules="mixinLoginEmailRule()"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-select class="ml-2" label="Country Code*" v-model="countryCode" item-text="label" item-value="value" :items="countryCodes" :menu-props="{ bottom: true, offsetY: true }" outlined dense required></v-select>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="6">
                          <v-text-field v-model="newPassword" outlined filled dense label="Enter New Password" :append-icon="isPwd2 ? 'mdi-eye' : 'mdi-eye-off'" :type="isPwd2 ? 'text' : 'password'" name="input-10-1" hint="At least 8 characters(Special Characters,Numbers and Capital letters)" @keydown.space="prevent_leading_space" @keyup.enter="validation_data()" @click:append="isPwd2 = !isPwd2" minlength="8" maxlength="15" required :lazy-validation="lazy" color="black" style="font-size: 13px; color: black" :rules="mixinLoginPasswordRule()"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field v-model="confirmNewPassword" outlined filled dense class="ml-2" label="Confirm Password" :append-icon="isPwd4 ? 'mdi-eye' : 'mdi-eye-off'" :type="isPwd4 ? 'text' : 'password'" @keyup.enter="validation_data()" @click:append="isPwd4 = !isPwd4" @keydown.space="prevent_leading_space" color="black" style="font-size: 13px; color: black" :rules="mixinLoginPasswordRule()"></v-text-field>
                        </v-col>
                      </v-row>

                      <v-card-actions class="button-corner mt-8">
                        <v-btn class="blue--text ml-n2" @click="cancel_signup" outlined color="white">Cancel</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn class="button tabledata--text ml-n2" @click="back_to_device_info">Back</v-btn>
                        <v-btn :loading="btnLoader" @click="validation_data()" class="button tabledata--text mr-n2">Next</v-btn>
                      </v-card-actions>
                    </v-card-text>
                  </v-form>
                </v-window-item>

                <v-window-item v-if="componentCheck == 2">
                  <v-form ref="form" v-model="valid2" lazy-validation @submit.prevent="verify_code">
                    <v-card-text>
                      <div class="text-center">
                        <h3 class="title font-weight-light black--text mb-2"></h3>
                        <div class="pt-10 black--text" align="left" style="font-size: 14px">Enter the Activation Code</div>
                        <v-text-field outlined class="mt-4" dense filled label="Code" color="black" @keydown.space="prevent_leading_space" v-model="activationCode" style="font-size: 13px; color: black"></v-text-field>
                        <div align="left" class="black--text text--darken-3 mt-n4" style="font-size: 10px">*Enter the Activation Code sent to {{ userEmail }}.</div>
                      </div>
                    </v-card-text>

                    <v-card-actions class="justify-bottom mt-16">
                      <v-btn class="blue--text ml-2" @click="cancel_signup" outlined color="white">Cancel</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn class="button tabledata--text mt-6" outlined @click="componentCheck = 1">Back</v-btn>
                      <v-btn :loading="verifyLoader" class="button tabledata--text mr-2 mt-6" @click="verify_code()">Create</v-btn>
                    </v-card-actions>
                  </v-form>
                </v-window-item>

                <v-window-item v-if="componentCheck == 3">
                  <v-card-text>
                    <v-row class="review-info">
                      <v-col cols="6" class="column"> <b>License Name</b></v-col>
                      <v-col cols="6"> {{ licenseDetails.license_name }}</v-col>
                    </v-row>
                    <v-row class="review-info">
                      <v-col cols="6"> <b>License Type</b></v-col>
                      <v-col cols="6"> {{ licenseDetails.license_type }}</v-col>
                    </v-row>
                    <v-row class="review-info">
                      <v-col cols="6"> <b>License Period</b></v-col>
                      <v-col cols="6">
                        {{ licenseDetails.license_period }} month{{
                          licenseDetails.license_period > 1 ? "s" : ""
                        }}</v-col
                      >
                    </v-row>
                    <v-row class="review-info">
                      <v-col cols="6"> <b>Days of Notification</b></v-col>
                      <v-col cols="6"> {{ licenseDetails.days_of_notification }}</v-col>
                    </v-row>
                    <v-row class="review-info">
                      <v-col cols="6"> <b>Router</b></v-col>
                      <v-col cols="6"> {{ licenseDetails.router_id }}</v-col>
                    </v-row>
                    <v-row class="review-info">
                      <v-col cols="6"> <b>License Key</b></v-col>
                      <v-col cols="6"> {{ licenseDetails.license_key }}</v-col>
                    </v-row>
                    <v-row style="width: 75%; text-align: left" class="mt-5">
                      <v-spacer></v-spacer>
                      <v-btn @click="validate_ok()" :loading="loginloader" class="button tabledata--text">Done</v-btn>
                    </v-row>
                  </v-card-text>
                  <v-card flat v-if="confirmCheck == true">
                    <v-dialog v-model="confirmDialog" persistent overflow-hidden max-width="500px">
                      <v-card class="dialogCard cardBg overflow-hidden">
                        <v-toolbar class="dialogToolbar cardBg" dense>
                          <v-toolbar-title> {{ device.modelSelect }}</v-toolbar-title>
                          <v-spacer></v-spacer>
                        </v-toolbar>

                        <v-card-text class="cardBg pt-6 justify-center">
                          <div v-if="device.modelSelect.substring(0, 4) == 'RG21'">
                            <v-img aspect-ratio="1.5" contain src="@/assets/Restartyourrouter.png"> </v-img>
                          </div>
                          <div v-if="device.modelSelect == 'FX20'">
                            <v-row>
                              <v-col cols="6">
                                <v-img aspect-ratio="1.0" contain src="@/assets/FX20InternetConnection.png"> </v-img>
                                Make sure your device has an internet connection.
                              </v-col>
                              <v-col cols="6">
                                <v-img aspect-ratio="1.0" contain src="@/assets/FX20-Powercycle.png"> </v-img>
                                1.Power down device.
                                <br />
                                2.Power on device.
                              </v-col>
                            </v-row>
                          </div>
                        </v-card-text>

                        <v-card-actions class="button-corner">
                          <v-spacer></v-spacer>

                          <v-btn class="button tabledata--text mr-2" :loading="loginloaderOk" @click="move_to_login()">OK</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-card>
                </v-window-item>
              </v-window>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import { mixin_form_validation_utils } from "@/mixins/formValidationUtils";
import AddDeviceInd from "@/views/AddDeviceInd.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { Auth } from "aws-amplify";
import axios from "axios";

export default {
  mixins: [mixin_form_validation_utils],
  components: {
    AddDeviceInd,
  },
  data: () => ({
    userFirstName: "",
    userLastName: "",
    newPassword: "",
    confirmNewPassword: "",
    userLastName: "",
    newPassword: "",
    confirmNewPassword: "",
    valid3: "",
    valid2: "",
    valid1: "",
    activationCode: "",
    userEmail: "",
    step: null,
    countryCode: "USA",
    countryCodes: ["USA"],
    verifyLoader: false,
    lazy: false,
    btnLoader: false,
    isPwd2: false,
    isPwd4: false,
    value: 0,
    componentCheck: 0,
    userDetails: {},
    deviceDetails: {},
    deviceDetailsEntered: false,
    device: {},
    loginloader: false,
    confirmCheck: false,
    confirmDialog: true,
    loginloaderOk: false,
    licenseDetails: {},
  }),
  methods: {
    ...mapActions(["actionCreateCustomer", "actionCreateSubscription"]),
    ...mapMutations(["mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    set_limit(value) {
      if (value.length > this.maxLength) value = value.slice(0, this.maxLength);
    },
    async createStripeAccount() {
      const email = this.userEmail;
      const routerId = this.device.modelSelect + "/" + this.deviceDetails.imei_number;
      const customerResult = await this.actionCreateCustomer(email);
      const stripeCustomerId = JSON.parse(customerResult.create_stripe_customer).id;
      await this.actionCreateSubscription({
        routerId: routerId,
        stripeCustomerId: stripeCustomerId,
        region: this.countryCode,
      });
    },
    validation_data() {
      if (!this.$refs.form1.validate()) {
        this.triggerSnackBar("error", "Enter the mandatory fields");
        return;
      }
      if (this.newPassword !== this.confirmNewPassword) {
        this.triggerSnackBar("error", "The passwords do not match");
        return;
      }
      this.send_verification_code();
    },
    async send_verification_code() {
      this.btnLoader = true;
      axios({
        method: "POST",
        // 05-24-2023: Mapped the mdm_router APIs for the production server
        url: process.env.VUE_APP_MDM_ROUTER_API + "/requesting_otp_for_the_verfication",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          command: "requestingOtpForTheVerfication",
          user_email_id: this.userEmail.toLowerCase(),
          user_country_code: this.countryCode,
        },
      })
        .then((res) => {
          // console.log(res);
          if (res.data.errorType == "Error") {
            this.btnLoader = false;
            this.triggerSnackBar("error", res.data.errorMessage);
          } else if (res.data.Message == "OTP has been delivered over email") {
            this.btnLoader = false;
            this.componentCheck = 2;
            this.triggerSnackBar("success", `OTP has been delivered to ${this.userEmail}`);
          } else {
            this.btnLoader = false;
            this.componentCheck = 2;
            this.triggerSnackBar("error", res.data.Message);
          }
        })
        .catch((err) => {
          this.triggerSnackBar("error", err);
          this.btnLoader = false;
          console.log(err);
        });
    },

    async verify_code() {
      if (this.verifyLoader === false) {
        this.verifyLoader = true;
        axios({
          method: "POST",
          // 05-24-2023: Mapped the mdm_router APIs for the production server
          url: process.env.VUE_APP_MDM_ROUTER_API + "/verfiying_email_and_create_user",
          headers: {
            "Content-Type": "application/json",
          },

          data: {
            command: "verfiyingEmailAndCreateUser",
            user_first_name: this.userFirstName,
            user_last_name: this.userLastName,
            user_email_id: this.userEmail.toLowerCase(),
            user_country_code: this.countryCode,
            verification_code: this.activationCode,
            user_created_from: "PORTAL",
          },
        })
          .then((res) => {
            // console.log(res);
            if (res.data.errorType == "Error") {
              this.verifyLoader = false;
              this.triggerSnackBar("error", res.data.errorMessage);
            } else {
              this.userDetails = res.data.user;
              this.call_cognito();
              // console.log(this.userDetails);
            }
          })
          .catch((err) => {
            console.log(err);
            this.verifyLoader = false;
            this.triggerSnackBar("error", err);
            this.btnLoader = false;
          });
      }
    },

    async call_cognito() {
      axios({
        method: "POST",
        // 05-24-2023: Mapped the mdm_router APIs for the production server
        url: process.env.VUE_APP_MDM_ROUTER_API + "/create_user_params_cognito",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          command: "createUserParamsCognito",
          user_email_id: this.userEmail.toLowerCase(),
          user_password: this.newPassword,
        },
      })
        // sign in step in required for the create customer action below to be valid
        // stripe action requires the user to be logged in
        .then(async () => {
          await Auth.signIn(this.userEmail.toLowerCase(), this.newPassword);
        })
        .then(async (res) => {
          let customer = await this.actionCreateCustomer(
            this.userDetails.user_id
          );
          customer = JSON.parse(customer.create_stripe_customer);
          this.add_device_to_user(customer);
        })
        .catch((err) => {
          console.log(err);
          this.triggerSnackBar("error", err);
          this.verifyLoader = false;
        });
    },

    add_device_to_user(customer) {
      this.deviceDetails.user_id = this.userDetails.user_id;
      this.deviceDetails.name = `${this.userFirstName} ${this.userLastName}`;
      this.deviceDetails.acc_type = "individual";
      this.deviceDetails.customer_id = customer.id;
      this.deviceDetails.user_email_id = this.userEmail.toLowerCase();
      var inputData = this.deviceDetails;
      // console.log(process.env.VUE_APP_MDM_ROUTER_API);
      axios({
        method: "POST",
        // 05-24-2023: Mapped the mdm_router APIs for the production server
        url: process.env.VUE_APP_MDM_ROUTER_API + "/individal_bulk_upload_router",
        headers: {
          "Content-Type": "application/json",
        },
        data: inputData,
      })
        .then((res) => {
          // console.log(res);
          if (res.data.errorType == "Error") {
            this.verifyLoader = false;
            this.triggerSnackBar("error", res.data.errorMessage);
          } else {
            this.licenseDetails = res.data.license;
            this.verifyLoader = false;
            this.triggerSnackBar("success", "Account Created Successfully!!!");
            this.componentCheck = 3;
          }
        })
        .catch((err) => {
          console.log(err);
          this.verifyLoader = false;
        });
    },

    prevent_leading_space(e) {
      if (!e.target.value) e.preventDefault();
      else if (e.target.value[0] == " ") e.target.value = e.target.value.replace(/^\s*/, "");
    },
    specal_charaters(event) {
      if (event.type == "paste") {
        var clipboardData = event.clipboardData || window.clipboardData;
        var text = clipboardData.getData("Text");
        var reg = /^[a-zA-Z0-9- ]+$/;
        if (!reg.test(text)) {
          event.preventDefault();
        } else {
          return false;
        }
      }
      var regex = new RegExp("^[a-zA-Z0-9- ]+$");
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (!regex.test(key)) {
        event.preventDefault();
        return false;
      }
    },

    acc_details(deviceDetails, device) {
      this.componentCheck = 1;
      this.deviceDetails = deviceDetails;
      this.device = device;
    },

    cancel_signup() {
      this.componentCheck = 0;
      this.deviceDetailsEntered = false;
      this.$router.push("/");
    },

    back_to_device_info() {
      this.deviceDetailsEntered = true;
      this.componentCheck = 0;
    },

    validate_ok() {
      this.confirmCheck = true;
    },

    async move_to_login() {
      this.loginloaderOk = true;
      try {
        const user = await Auth.signIn(this.userEmail.toLowerCase(), this.newPassword);
        this.$router.push("/");

        this.loginloaderOk = false;
        this.confirmDialog = true;
      } catch (error) {
        this.loginloaderOk = false;
        this.triggerSnackBar("error", error.message);
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.left-aligned-text {
  text-align: left;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 25%;
  border-bottom: 1px;
}

.divider-vertical::after {
  content: "";
  position: absolute;
  top: 10%;
  left: 50%;
  border-right: 1px solid #ccc;
  height: 70%;
}

.review-info {
  font-size: 16px;
  border: 1px solid #ccc;
  text-align: left;
  width: 75%;
}
</style>
