import { API, graphqlOperation } from "aws-amplify";
import { get_subscription_list, get_product_list, does_subscription_exist, create_stripe_customer, create_stripe_subscription, create_customer_portal_session, delete_stripe_customer } from "@/graphql";
// ------------------------------ Queries ------------------------------
// Get subscription list for super admin dashboard
export const getSubscriptionList = async () => {
  try {
    const result = await API.graphql(graphqlOperation(get_subscription_list));
    const response = JSON.parse(result.data.get_subscription_list);
    return response.data;
  } catch (error) {
    console.error("Error fetching subscription details", error);
    throw error;
  }
};

export const getStripeProductList = async () => {
  try {
    const result = await API.graphql(graphqlOperation(get_product_list));
    const response = JSON.parse(result.data.get_product_list);
    return response;
  } catch (error) {
    console.error("Error fetching product details", error);
    throw error;
  }
};

export const getCheckSubscriptionExist = async (email, model, country, type) => {
  try {
    const result = await API.graphql(
      graphqlOperation(does_subscription_exist, {
        input: {
          user_email_id: email,
          model: model,
          license_country: country,
          acc_type: type,
        },
      })
    );
    const response = JSON.parse(result.data.does_subscription_exist);
    return response;
  } catch (error) {
    // error is not printed on purpose
    throw error;
  }
};

// ------------------------------ Mutations ------------------------------

export const createStripeCustomer = async (user_id) => {
    try {
        console.log(user_id);
        const result = await API.graphql(
            graphqlOperation(create_stripe_customer, {
                input: { user_id: user_id }
            })
        );
        const response = result.data;
        return response;
    } catch (error) {
        console.error("Error fetching creating stripe customer", error);
        throw error;
    }
}

export const createStripeSubscription = async (routerId, stripeCustomerId, licenseCountry) => {
    try {
        console.log(routerId, stripeCustomerId, licenseCountry)
        const result = await API.graphql(
            graphqlOperation(create_stripe_subscription, {
                input: { router_id: routerId, stripe_customer_id: stripeCustomerId, license_country: licenseCountry }
            })
        )
        return result;
    } catch (error) {
        console.error("Error creating stripe subcription", error);
    }
}

export const createCustomerPortalSession = async (email, routerModel, country) => {
    try {
        const result = await API.graphql(
            graphqlOperation(create_customer_portal_session, {
                input: { user_email_id: email, router_model: routerModel, country: country }
            })
        )
        return result;
    } catch (error) {
        console.log("Error creating customer portal session", error);
        throw error;
    }
}

export const deleteStripeCustomer = async (email) => {
    try {
        const result = await API.graphql(graphqlOperation(delete_stripe_customer, {
            input: { user_email_id: email }
        })
        )
        return result;
    } catch (error) {
        console.log("Error deleting stripe customer: ", error)
    }
}
