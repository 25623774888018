import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

// Pages
import LoginPage from "@/views/LoginPage.vue";
import SignUp from "@/views/SignUp.vue";
import Home from "@/views/TopBar.vue";
import Login from "@/views/Login.vue";

import DashboardSAdmin from "@/components/Dashboard/DashboardSAdmin.vue";
import Dashboard from "@/components/Dashboard/Dashboard.vue";

import IndividualDeviceList from "@/components/Devices/IndividualDeviceList.vue";
import CustomerDeviceList from "@/components/Devices/CustomerDeviceList.vue";
import SuperIndividualDeviceList from "@/components/Devices/SuperIndividualDeviceList.vue";
import SuperBusinessDeviceList from "@/components/Devices/SuperBusinessDeviceList.vue";
import Organization from "@/components/SuperAdminAcces/Organizations/SuperOrganization.vue";
import DeviceGroupsList from "@/components/Groups/DeviceGroupsList.vue";
import FotaPackage from "@/components/SuperAdminAcces/FotaPackage.vue";
import BulkUpload from "@/components/SuperAdminAcces/BulkUpload.vue";

import ProductModel from "@/components/SuperAdminAcces/ProductModel.vue";
import Config from "@/components/SuperAdminAcces/Config.vue";
import Licenses from "@/components/SuperAdminAcces/Licenses/Licenses.vue";

import BusinessUsers from "@/components/SuperAdminAcces/Accounts/BusinessUsers.vue";
import IndividualUsers from "@/components/SuperAdminAcces/Accounts/IndividualUsers.vue";

import AdminLogs from "@/components/EventLogs/AdminLogs.vue";
import AlertLogs from "@/components/EventLogs/AlertLogs.vue";
import LicenseReport from "@/components/Reports/LicenseReport.vue";

// Not Used
import CarrierReport from "@/components/Reports/CarrierReport.vue";
import PaymentStatus from "@/components/Reports/PaymentStatus.vue";
import Alerts from "@/components/Dashboard/Alerts.vue";
import FotaLogs from "@/components/EventLogs/FotaLogs.vue";
import PartnersUsers from "@/components/SuperAdminAcces/Accounts/PartnersUsers.vue";
import DataUsageReport from "@/components/Reports/DataUsageReport.vue";
import AdminLogsReport from "@/components/Reports/AdminLogsReport.vue";
import AlertLogsReport from "@/components/Reports/AlertLogsReport.vue";
// import PricingPage from "@/components/Stripe/PricingPage.vue";

import NotFound from "@/views/NotFound.vue";

Vue.use(VueRouter);

// Ensure the user is logged out when they reach login page
const autoLogOut = async (to, from, next) => {
  if (store.getters.getterGetIsAuthenticated) {
    store.dispatch("actionLogOut", store.getters.getterGetCurrentUserEmail);
    next();
  }
  next();
};

const routes = [
  { path: "/", name: "LoginPage", component: Login, beforeEnter: autoLogOut },
  // { path: "/Login", name: "Login", component: Login, beforeEnter: autoLogOut },
  { path: "/SignUp", name: "SignUp", component: SignUp },
  {
    path: "/Home",
    name: "Home",
    component: Home,
    props: true,
    children: [
      { name: "Dashboard", path: "Dashboard", component: Dashboard, meta: { requiresAuth: true, roles: ["C_ADMIN", "C_ADMIN_1"] } },
      { name: "DashboardSAdmin", path: "DashboardSAdmin", component: DashboardSAdmin, meta: { requiresAuth: true, roles: ["SU_ADMIN"] } },
      // Devices/Manage
      { name: "SuperBusinessDeviceList", path: "SuperBusinessDeviceList", component: SuperBusinessDeviceList, meta: { requiresAuth: true, roles: ["SU_ADMIN"] } },
      { name: "SuperIndividualDeviceList", path: "SuperIndividualDeviceList", component: SuperIndividualDeviceList, meta: { requiresAuth: true, roles: ["SU_ADMIN"] } },
      { name: "CustomerDeviceList", path: "CustomerDeviceList", component: CustomerDeviceList, meta: { requiresAuth: true, roles: ["C_ADMIN", "P_ADMIN", "C_ADMIN_1", "SP_ADMIN"] } },
      { name: "Organization", path: "Organization", component: Organization, meta: { requiresAuth: true, roles: ["SU_ADMIN", "P_ADMIN", "SP_ADMIN"] } },
      { name: "DeviceGroupsList", path: "DeviceGroupsList", component: DeviceGroupsList, meta: { requiresAuth: true, roles: ["SU_ADMIN", "C_ADMIN", "P_ADMIN", "C_ADMIN_1", "G_M_ADMIN", "SP_ADMIN"] } },
      { name: "BulkUpload", path: "BulkUpload", component: BulkUpload, meta: { requiresAuth: true, roles: ["SU_ADMIN"] } },
      { name: "FotaPackage", path: "FotaPackage", component: FotaPackage, meta: { requiresAuth: true, roles: ["SU_ADMIN"] } },
      // Device
      { name: "ProductModel", path: "ProductModel", component: ProductModel },
      { name: "Config", path: "Config", component: Config, meta: { requiresAuth: true, roles: ["SU_ADMIN"] } },
      { name: "Licenses", path: "Licenses", component: Licenses, meta: { requiresAuth: true, roles: ["SU_ADMIN", "SP_ADMIN"] } },
      // Users/Accounts
      { name: "BusinessUsers", path: "BusinessUsers", component: BusinessUsers, meta: { requiresAuth: true, roles: ["SU_ADMIN", "C_ADMIN", "C_ADMIN_1", "SP_ADMIN"] } },
      { name: "IndividualUsers", path: "IndividualUsers", component: IndividualUsers, meta: { requiresAuth: true, roles: ["SU_ADMIN", "SP_ADMIN"] } },
      // Report
      { name: "LicenseReport", path: "LicenseReport", component: LicenseReport, meta: { requiresAuth: true, roles: ["SU_ADMIN"] } },
      // Customer Admin Event Logs
      { name: "AdminLogs", path: "AdminLogs", component: AdminLogs, meta: { requiresAuth: true, roles: ["C_ADMIN", "C_ADMIN_1"] } },
      { name: "AlertLogs", path: "AlertLogs", component: AlertLogs, meta: { requiresAuth: true, roles: ["C_ADMIN", "C_ADMIN_1"] } },
      // Individual user page
      { name: "IndividualDeviceList", path: "IndividualDeviceList", component: IndividualDeviceList, meta: { requiresAuth: true, roles: ["INDIVIDUAL", "G_M_ADMIN"] } },

      // Not Used
      { name: "CarrierReport", path: "CarrierReport", component: CarrierReport },
      { name: "PaymentStatus", path: "PaymentStatus", component: PaymentStatus },
      { name: "Alerts", path: "Alerts", component: Alerts },
      { name: "FOTALogs", path: "FotaLogs", component: FotaLogs },
      { name: "Users", path: "Users", component: PartnersUsers },
      { name: "DataUsageReport", path: "DataUsageReport", component: DataUsageReport },
      { name: "AdminLogsReport", path: "AdminLogsReport", component: AdminLogsReport },
      { name: "AlertLogsReport", path: "AlertLogsReport", component: AlertLogsReport },
      // { name: "PricingPage", path: "PricingPage", component: PricingPage },
    ],
  },
  { path: "/:catchAll(.*)", name: "NotFound", component: NotFound },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
// SU_ADMIN - Super Admin
// C_ADMIN - Customer Admin
// P_ADMIN - Partner Admin
// C_ADMIN_1 - Organization Admin
// INDIVIDUAL - Individual User
const getDefaultPath = { SU_ADMIN: "DashboardSAdmin", C_ADMIN: "Dashboard", SP_ADMIN: "CustomerDeviceList", P_ADMIN: "CustomerDeviceList", G_M_ADMIN: "IndividualDeviceList", INDIVIDUAL: "IndividualDeviceList", C_ADMIN_1: "Dashboard" };

const triggerSnackbar = (type, text) => {
  store.commit("mutationSetSnackBarItem", { type, text });
};

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const userRole = store.getters.getterGetCurrentUserRole;
  const isAuthorized = store.getters.getterGetIsAuthenticated;

  // triggerSnackbar("notification", `Current role: ${userRole} - Current Email: ${store.getters.getterGetCurrentUserEmail}`)

  if (!isAuthorized && requiresAuth) {
    next({ path: "/" }); // Redirect to Home if not authenticated
    triggerSnackbar("notification", "Please login");
  }

  if (isAuthorized && requiresAuth) {
    const roles = to.meta.roles;
    if (roles && roles.length > 0 && !roles.includes(userRole)) {
      console.log(from.path);
      next({ name: "Home" }); // Redirect to Home if the user does not have the correct role
      triggerSnackbar("error", "You do not have access to this page");
    } else {
      next(); // Proceed to the route
    }
  } else {
    next(); // Proceed to the route if it does not require authentication
  }
});

router.beforeEach((to, from, next) => {
  const userRole = store.getters.getterGetCurrentUserRole;
  if ((to.path === "/Home" || to.path === "/Home/") && store.getters.getterGetIsAuthenticated) {
    next({ name: getDefaultPath[userRole] });
  } else if ((to.path === "/Home" || to.path === "/Home/") && !store.getters.getterGetIsAuthenticated) {
    next({ path: "/" });
  } else {
    next();
  }
});

export default router;
