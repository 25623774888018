<template>
  <v-card>
    <v-card-text>
      <v-card-text>
        <v-form ref="form">
          <p class="text-subtitle-1 text-center font-weight-light mb-2">Multi-Factor Authentication</p>

          <v-text-field style="font-size: 1rem" outlined filled dense label="Enter your 6 digit MFA token" maxlength="6" v-model="mfaCode" :rules="mixinMfaCodeRule()" @keydown.space.prevent @keydown.enter.prevent="mfa_login()"></v-text-field>

        </v-form>
      </v-card-text>
    </v-card-text>

    <v-card-actions class="d-flex justify-center mb-0 pb-0">
      <v-btn text color="#64B5F6" :loading="loading" @click="back()">Back</v-btn>
      <v-spacer></v-spacer>
      <v-btn text depressed color="#64B5F6" :loading="loading" @click="mfa_login()">Next</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mixin_form_validation_utils } from "@/mixins/formValidationUtils";
import { mapActions, mapGetters, mapMutations } from "vuex";

import { confirmSignIn } from "@/services";
import { utilHandleExceptions } from "@/utils";

export default {
  mixins: [mixin_form_validation_utils],
  data() {
    return {
      loading: false,
      mfaCode: "",
    }
  },
  computed: {
    ...mapGetters(["getterGetUsername", "getterGetWindowPage", "getterGetLoginInfo", "getterGetMfaLoginStatus"]),
    username: {
      get() {
        return this.getterGetUsername;
      },
      set(value) {
        this.mutationSetUsername(value);
      },
    },
    windowPage: {
      get() {
        return this.getterGetWindowPage;
      },
      set(value) {
        this.mutationSetWindowPage(value);
      },
    },
    loginInfo: {
      get() {
        return this.getterGetLoginInfo;
      },
      set(value) {
        this.mutationSetLoginInfo(value);
      },
    }
  },
  methods: {
    ...mapActions(["actionGetUserInfo", "actionResetLoginInfo", "actionSetMfaFailedLoginAttempt"]),
    ...mapMutations(["mutationSetUsername", "mutationSetWindowPage", "mutationSetLoginInfo", "mutationSetMfaLoginStatus", "mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    back() {
      this.windowPage = "Login";
    },
    async mfa_login() {
      this.mutationSetMfaLoginStatus(Date.now());
      if(!this.$refs.form.validate()) return this.triggerSnackBar("error", "Wrong code format! Please enter a valid 6 digit code."); 
      if(!this.getterGetMfaLoginStatus.isLoginAvailable) return this.triggerSnackBar("error", `Login failed due to too many failed login attempts. You have ${this.getterGetMfaLoginStatus.timeLeft} min(s) left until you can try again.`);

      try {
        this.loading = true;
        await confirmSignIn(this.loginInfo, this.mfaCode);
        await this.actionGetUserInfo(this.username);
        this.actionResetLoginInfo();
        this.triggerSnackBar("success", "Welcome");
      } catch (error) {
        if(error.code === "CodeMismatchException") {
          this.mutationSetMfaLoginStatus(Date.now());
          this.actionSetMfaFailedLoginAttempt();
          this.triggerSnackBar("error", `Code Mismatch! You have ${this.getterGetMfaLoginStatus.loginAttemptsLeft} attempts left.`);
        } else {
          this.triggerSnackBar("error", utilHandleExceptions(error));
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
