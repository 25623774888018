<template>
  <v-card>
    <v-card-text>
      <v-card-text>
        <v-form ref="form">
          <p class="text-center font-weight-light mb-2">Set a new password</p>
          <div class="mb-3 text-caption-2">*Enter the Activation Code sent to your registered email</div>
          <v-text-field outlined dense filled label="Code" v-model="activationCode" maxlength="6" :rules="mixinMfaCodeRule()" @keydown.space.prevent @keydown.enter.prevent="submit_reset_password()"></v-text-field>

          <div class="mb-3 text-caption-2">*Please enter a new password for your account</div>
          <v-text-field outlined filled dense :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword" label="Password" v-model="newPassword" :rules="mixinLoginPasswordRule()" @keydown.space.prevent @keydown.enter.prevent="submit_reset_password()"></v-text-field>

          <v-text-field outlined filled dense :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword" label="Confirm Password" v-model="confirmNewPassword" :rules="mixinLoginPasswordRule()" @keydown.space.prevent @keydown.enter.prevent="submit_reset_password()"></v-text-field>
        </v-form>
      </v-card-text>
    </v-card-text>

    <v-card-actions class="d-flex justify-center mb-0 pb-0">
      <v-btn text color="#64B5F6" @click="back()">Back</v-btn>

      <v-spacer></v-spacer>
      <v-btn text depressed color="#64B5F6" @click="submit_reset_password()">Next</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mixin_form_validation_utils } from "@/mixins/formValidationUtils";
import { mapGetters, mapMutations } from "vuex";

import { forgotPasswordSubmit } from "@/services";
import { utilHandleExceptions } from "@/utils";

export default {
  mixins: [mixin_form_validation_utils],
  data() {
    return {
      activationCode: "",
      newPassword: "",
      confirmNewPassword: "",

      showPassword: false,
    };
  },
  computed: {
    ...mapGetters(["getterGetWindowPage", "getterGetResetEmail"]),
    windowPage: {
      get() {
        return this.getterGetWindowPage;
      },
      set(value) {
        this.mutationSetWindowPage(value);
      },
    },
    resetEmail: {
      get() {
        return this.getterGetResetEmail;
      },
      set(value) {
        this.mutationSetResetEmail(value);
      },
    }
  },
  methods: {
    ...mapMutations(["mutationSetWindowPage", "mutationSetResetEmail", "mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    back() {
      this.windowPage = "Forgot-Password";
    },
    async submit_reset_password() {
      if(!this.$refs.form.validate()) return this.triggerSnackBar("error", "Enter all the mandatory fields.");
      if (this.newPassword !== this.confirmNewPassword) return this.triggerSnackBar("error", "The passwords do not match.");

      try {
        const response = await forgotPasswordSubmit(this.resetEmail, this.activationCode, this.newPassword);
        console.log(response)
        this.triggerSnackBar("success", "The password has been reset successfully.");
        this.resetEmail = "";
        this.windowPage = "Login";
      } catch (error) {
        this.triggerSnackBar("error", utilHandleExceptions(error));
      }
    },
  },
};
</script>
