<template>
  <v-main class="dashboardCard" app>
    <Advertisements class="ad-card" v-if="getInitialAdCheck && adDialog" :adDialog="adDialog" @close="closePopup" />

    <v-container fluid class="dashboardCard dashboard-background">
      <h2 class="my-4 font-weight-medium"><b>Accounts</b></h2>
      <v-btn class="mb-6" @click="resetPopup">reset popup</v-btn>

      <v-row class="mb-9" dense>
        <v-col v-for="(stat, index) in userStats" :key="index" lg="3" md="6" sm="12" cols="12">
          <v-hover v-slot="{ hover }" open-delay="200">
            <v-skeleton-loader v-if="skeletonScreen" class="skeleton-screen" type="image" height="91.5px"> </v-skeleton-loader>
            <v-card v-else :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="mixinChangeRoute(stat.link)">
              <v-card-text>
                <b class="dashboard-router black--text">{{ stat.title }}</b>
                <div>
                  <v-row class="d-flex justify-end pr-8">
                    <div class="d-flex align-end text-h2 blue--text">
                      <b class="dashboard-routerCount">{{ stat.count }}</b>
                    </div>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>

      <h2 class="my-4 font-weight-medium"><b>Devices</b></h2>

      <v-row v-if="skeletonScreen">
        <v-col v-for="device in [0, 1, 2, 3, 4, 5]" :key="device" lg="6" sm="12" cols="12">
          <v-card class="ma-0" flat>
            <v-skeleton-loader v-if="skeletonScreen" class="skeleton-screen" type="image" height="189px"> </v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col v-for="device in deviceModels" :key="device.model" lg="6" sm="12" cols="12">
          <v-card class="ma-0 pa-2" flat>
            <v-card-text class="black--text">
              <v-row class="d-flex justify-left align-start text-h5 mb-2" md="3" cols="12">
                <h3 class="ml-4">{{ modelToName(device.model) ? modelToName(device.model) : device.model }}</h3>
              </v-row>
              <v-row class="pa-2 dashboard-link-card" no-gutters @click="mixinDataChangeRoute('SuperBusinessDeviceList', { model: modelToName(device.model) })">
                <v-col class="indigo--text text--lighten-2 text-subtitle-1 font-weight-bold" cols="4">Business Devices</v-col>
                <v-row class="d-flex" dense>
                  <v-col class="text-center" cols="4">
                    <div>Total</div>
                    <div class="font-weight-bold text-h6">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on" class="ml-1 no-text-wrap">{{ device.business.total }}</div>
                        </template>
                        <span>{{ device.business.total }}</span>
                      </v-tooltip>
                    </div>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col class="text-center" cols="4">
                    <div>Online</div>
                    <div class="font-weight-bold green--text text-h6">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on" class="ml-1 no-text-wrap">{{ device.business.online }}</div>
                        </template>
                        <span>{{ device.business.online }}</span>
                      </v-tooltip>
                    </div>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col class="text-center" cols="4">
                    <div>Offline</div>
                    <div class="font-weight-bold grey--text text-h6">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on" class="ml-1 no-text-wrap">{{ device.business.offline }}</div>
                        </template>
                        <span>{{ device.business.offline }}</span>
                      </v-tooltip>
                    </div>
                  </v-col>
                </v-row>
              </v-row>
              <v-divider></v-divider>
              <v-row class="pa-2 dashboard-link-card" no-gutters @click="mixinDataChangeRoute('SuperIndividualDeviceList', { model: modelToName(device.model) })">
                <v-col class="deep-purple--text text--lighten-2 text-subtitle-1 font-weight-bold" cols="4">Individual Devices</v-col>
                <v-row class="d-flex" dense>
                  <v-col class="text-center" cols="4">
                    <div>Total</div>
                    <div class="font-weight-bold text-h6">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on" class="ml-1 no-text-wrap">{{ device.individual.total }}</div>
                        </template>
                        <span>{{ device.individual.total }}</span>
                      </v-tooltip>
                    </div>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col class="text-center" cols="4">
                    <div>Online</div>
                    <div class="font-weight-bold green--text text-h6">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on" class="ml-1 no-text-wrap">
                            {{ device.individual.online }}
                          </div>
                        </template>
                        <span>{{ device.individual.online }}</span>
                      </v-tooltip>
                    </div>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col class="text-center" cols="4">
                    <div>Offline</div>
                    <div class="font-weight-bold grey--text text-h6">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on" class="ml-1 no-text-wrap">
                            {{ device.individual.offline }}
                          </div>
                        </template>
                        <span>{{ device.individual.offline }}</span>
                      </v-tooltip>
                    </div>
                  </v-col>
                </v-row>
              </v-row>
              <v-divider></v-divider>
              <v-row class="pa-2 dashboard-link-card" no-gutters>
                <v-col class="deep-purple--text text--lighten-2 text-subtitle-1 font-weight-bold no-text-wrap" cols="4">Individual Subscriptions</v-col>
                <v-row class="d-flex" dense>
                  <v-col class="text-center" cols="32">
                    <div>Total</div>
                    <div class="font-weight-bold text-h6">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on" class="ml-1 no-text-wrap">
                            {{ device.individualSubscription ? device.individualSubscription.Total : 0 }}
                          </div>
                        </template>
                        <span>
                          {{ device.individualSubscription ? device.individualSubscription.Total : 0 }}
                        </span>
                      </v-tooltip>
                    </div>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col class="text-center" cols="3">
                    <div>Active</div>
                    <div class="font-weight-bold green--text text-h6">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on" class="ml-1 no-text-wrap">
                            {{ device.individualSubscription.Active }}
                          </div>
                        </template>
                        <span>{{ device.individualSubscription.Active }}</span>
                      </v-tooltip>
                    </div>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col class="text-center" cols="3">
                    <div>Trial</div>
                    <div class="font-weight-bold orange--text text-h6">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on" class="ml-1 no-text-wrap">
                            {{ device.individualSubscription.Trial }}
                          </div>
                        </template>
                        <span>{{ device.individualSubscription.Trial }}</span>
                      </v-tooltip>
                    </div>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col class="text-center" cols="3">
                    <div>Inactive</div>
                    <div class="font-weight-bold grey--text text-h6">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on" class="ml-1 no-text-wrap">
                            {{ device.individualSubscription.Inactive }}
                          </div>
                        </template>
                        <span>{{ device.individualSubscription.Inactive }}</span>
                      </v-tooltip>
                    </div>
                  </v-col>
                </v-row>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import Advertisements from "@/components/Dashboard/Advertisements.vue";
import { mixin_routing_utils } from "@/mixins/routingUtils.js";
import { router_model_utils } from "@/mixins/routerModelUtils.js";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  mixins: [router_model_utils, mixin_routing_utils],
  components: {
    Advertisements,
  },
  data() {
    return {
      userStats: {
        businessUsers: {
          title: "Business Users",
          count: 0,
          link: "/Home/BusinessUsers",
        },
        individualUsers: {
          title: "Individual Users",
          count: 0,
          link: "/Home/IndividualUsers",
        },
        organization: {
          title: "Organization",
          count: 0,
          link: "/Home/Organization",
        },
        groups: { title: "Groups", count: 0, link: "/Home/DeviceGroupsList" },
      },
      deviceModels: [],
      adDialog: false,
      doNotShowValue: null,
      skeletonScreen: true,
    };
  },
  created() {
    this.getSuperAdminStats();
    this.getInitialAdCheck ? (this.adDialog = true) : (this.adDialog = false);
  },
  computed: {
    ...mapGetters(["getterGetInitialAdCheck", "getterGetSuperAdminDashboardInfo", "getterGetSubscriptionList"]),
    getInitialAdCheck() {
      return this.getterGetInitialAdCheck;
    },
    getSuperAdminDashboardInfo() {
      return this.getterGetSuperAdminDashboardInfo;
    },
    getSubscriptionInfo() {
      return this.getterGetSubscriptionList;
    },
  },
  methods: {
    ...mapActions(["actionGetSuperAdminDashBoardDetails", "actionGetSubscriptionList", "actionCreateCustomer", "actionCreateSubscription"]),
    ...mapMutations(["mutationSetInitialAdCheck", "mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    closePopup() {
      this.adDialog = false;
    },
    resetPopup() {
      this.mutationSetInitialAdCheck(true);
      this.adDialog = true;
    },
    async getSuperAdminStats() {
      this.skeletonScreen = true;
      try {
        await this.actionGetSuperAdminDashBoardDetails();
        const dashboardInfo = this.getSuperAdminDashboardInfo;
        const subscriptions = await this.getSubscriptionDetails();
        let subscriptionKeys = Object.keys(subscriptions);
        // Mapping object for subscription key suffixes
        const suffixMapping = {
          RgModel: "RG2100",
          Rg2Model: "RG2102",
          fxModel: "FX20",
          CgModel: "CG890",
        };
        //Initialize with values if none exist
        const defaultSubscription = {
          Total: 0,
          Trial: 0,
          Active: 0,
          Inactive: 0,
        };
        // Function to get subscription key for a given element and suffix
        const getSubscriptionKey = (subscriptionKeys, suffix, type) => {
          return subscriptionKeys.find((key) => key.includes(`${type} ${suffix}`));
        };
        Object.keys(dashboardInfo).forEach((element) => {
          if (element.endsWith("Model") && suffixMapping.hasOwnProperty(element)) {
            const suffix = suffixMapping[element] || ""; // Check if mapping exists
            const individualKey = getSubscriptionKey(subscriptionKeys, suffix, "Individual");
            const businessKey = getSubscriptionKey(subscriptionKeys, suffix, "Business");
            this.deviceModels.push({
              model: element,
              individual: {
                online: dashboardInfo[element].individual.online_routers,
                offline: dashboardInfo[element].individual.offline_routers,
                registered: dashboardInfo[element].individual.registered_routers,
                total: dashboardInfo[element].individual.count,
              },
              individualSubscription: subscriptions[individualKey] ? subscriptions[individualKey] : defaultSubscription,
              business: {
                online: dashboardInfo[element].business.online_routers,
                offline: dashboardInfo[element].business.offline_routers,
                registered: dashboardInfo[element].business.registered_routers,
                total: dashboardInfo[element].business.count,
              },
              businessSubscription: subscriptions[businessKey] ? subscriptions[businessKey] : defaultSubscription,
            });
          }
        });
        this.userStats.businessUsers.count = dashboardInfo.no_business_users;
        this.userStats.individualUsers.count = dashboardInfo.no_individual_users;
        this.userStats.organization.count = dashboardInfo.no_organization;
        this.userStats.groups.count = dashboardInfo.no_groups;
      } catch (err) {
        this.triggerSnackBar("error", err.errors ? err.errors[0].message : err);
      } finally {
        this.skeletonScreen = false;
      }
    },
    async getSubscriptionDetails() {
      const organizedSubscriptions = {};
      await this.actionGetSubscriptionList();
      let individualSubscriptionList = this.getSubscriptionInfo;
      // console.log(individualSubscriptionList);
      individualSubscriptionList.forEach((subscription) => {
        const routerId = subscription.metadata.hasOwnProperty("router_id") ? subscription.metadata.router_id : null;
        const status = subscription.status === "trialing" ? "Trial" : subscription.status === "active" ? "Active" : "Inactive";
        if (routerId && status) {
          const key = `Individual ${routerId.split("/")[0]}`;
          if (!organizedSubscriptions[key]) {
            organizedSubscriptions[key] = {
              Trial: 0,
              Active: 0,
              Inactive: 0,
              Total: 0,
            };
          }
          organizedSubscriptions[key][status]++;
          organizedSubscriptions[key]["Total"]++;
        }
      });
      return organizedSubscriptions;
    },
  },
};
</script>

<style scoped>
.dashboard-link-card:hover {
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
}
.dashboard-background {
  max-width: 1500px;
  min-height: 100%;
}
.icon-overlay {
  position: fixed;
  top: 200px;
  right: 200px;
  z-index: 999;
  cursor: pointer;
}

.ad-card {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 998;
  max-width: 500px;
  width: 100%;
}

.close-button {
  z-index: 999;
}
.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.skeleton-screen {
  background-color: #d8d8d8;
}
</style>
