<template>
  <v-card>
    <v-card-text>
      <v-form ref="form">
        <v-text-field class="ma-2" style="font-size: 1rem" outlined filled dense clearable prepend-inner-icon="mdi-email" color="blue" label="Email*" v-model="username" :rules="mixinLoginEmailRule()" @keydown.space.prevent @keyup.enter="login()" />

        <v-text-field class="ma-2" style="font-size: 1rem" outlined filled dense clearable prepend-inner-icon="mdi-key" color="blue" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword" :rules="passwordRule()" label="Password*" v-model="password" @keydown.space.prevent @keyup.enter="login()" />

        <v-row class="justify-end mt-n5" no-gutters>
          <v-btn class="text-btn" text small depressed @click="windowPage = 'Forgot-Password'">Forgot Password?</v-btn>
        </v-row>

        <v-row class="my-2 mx-1 flex-nowrap align-start" no-gutters>
          <v-checkbox class="m-0" v-model="termsAndConditionsCheck" hide-details></v-checkbox>
          <p>I've read and will comply with the terms of <a href="https://www.jextream.net/terms-of-use" target="_blank" class="text-btn">Jextream MDM agreement</a></p>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-actions class="d-flex flex-column justify-center m-0" style="padding: 0">
      <v-btn class="my-2 white--text" shaped width="292" color="button" :disabled="!termsAndConditionsCheck" :loading="loading" @click="login()">Login</v-btn>
      <div class="d-flex align-center justify-center">
        <p class="grey--text">Don't have an account?</p>
        <v-btn text depressed class="text-btn px-2" to="/SignUp">Sign Up</v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import { mixin_form_validation_utils } from "@/mixins/formValidationUtils";
import { mixin_routing_utils } from "@/mixins/routingUtils";

import { signIn } from "@/services";
import { utilHandleExceptions } from "@/utils";

export default {
  mixins: [mixin_form_validation_utils, mixin_routing_utils],
  data() {
    return {
      showPassword: false,
      termsAndConditionsCheck: true,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["getterGetUsername", "getterGetPassword", "getterGetLoginInfo", "getterGetWindowPage"]),
    username: {
      get() {
        return this.getterGetUsername;
      },
      set(value) {
        this.mutationSetUsername(value);
      },
    },
    password: {
      get() {
        return this.getterGetPassword;
      },
      set(value) {
        this.mutationSetPassword(value);
      },
    },
    windowPage: {
      get() {
        return this.getterGetWindowPage;
      },
      set(value) {
        this.mutationSetWindowPage(value);
      },
    },
    loginInfo: {
      get() {
        return this.getterGetLoginInfo;
      },
      set(value) {
        this.mutationSetLoginInfo(value);
      },
    }
  },
  methods: {
    ...mapMutations(["mutationSetUsername", "mutationSetPassword", "mutationSetWindowPage", "mutationSetLoginInfo", "mutationSetSnackBarItem"]),
    ...mapActions(["actionGetUserInfo", "actionResetLoginInfo"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    async login() {
      if (!this.$refs.form.validate()) {
        return this.triggerSnackBar("error", "Please enter a proper username and password.");
      }
      try {
        this.loading = true;
        this.loginInfo = await signIn(this.username, this.password);

        if (["SMS_MFA", "SOFTWARE_TOKEN_MFA"].includes(this.loginInfo.challengeName)) return (this.windowPage = "MFA");
        if (this.loginInfo.challengeName === "NEW_PASSWORD_REQUIRED") return (this.windowPage = "New-Password");

        await this.actionGetUserInfo(this.username);
        this.actionResetLoginInfo();
        this.triggerSnackBar("success", "Welcome");
      } catch (error) {
        this.triggerSnackBar("error", utilHandleExceptions(error));
      } finally {
        this.loading = false;
      }
    },
    passwordRule() {
      return [(value) => !!value || "Password is required"];
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
}
.text-btn {
  text-decoration: underline;
  text-transform: none;
  font-weight: 500;
  color: #0195d2;
}
.text-btn:hover {
  color: #003f5a;
}
/* get rid of vuetify's background hover */
.text-btn::before {
  background-color: transparent !important;
}
/* get rid of default spacing for v-checkbox */
:deep .v-input--selection-controls {
  margin: 0;
  padding: 0;
}
</style>
