<template>
  <v-dialog v-model="deviceConfigDialog" overflow-hidden persistent fullscreen no-click-animation>
    <v-card class="main-layout" height="100%">
      <!-- header -->
      <v-toolbar dark flat height="64" color="blue lighten-1">
        <v-toolbar-title>
          <v-expand-x-transition>
            <v-btn v-if="checkScreenSize()" large class="mr-3" icon @click.stop="toggle_side_menu">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </v-expand-x-transition>
          <v-expand-x-transition>
            <v-icon v-if="!checkScreenSize()" large class="mr-3">mdi-cogs</v-icon>
          </v-expand-x-transition>
          <span v-if="isGroupConfig">Group Configuration: {{ singleRouterInfo.group_name }}</span>
          <span v-else class="config-dialog-title">{{ singleRouterInfo.router_id }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        
        <!-- <v-badge class="mr-4" :content="getErrorCount" :value="getErrorCount" color="red" overlap>
          <v-btn small icon @click.stop="open_error_list_panel">
            <v-icon :class="{ 'error-button-glow': getErrorCount !== 0 }">mdi-bell</v-icon>
          </v-btn>
        </v-badge> -->
        <v-btn small icon @click="toggle_help_panel">
          <v-icon>mdi-help-box-multiple</v-icon>
        </v-btn>
        <v-btn small icon @click="toggle_config_change_history_panel">
          <v-icon>mdi-history</v-icon>
        </v-btn>
        <v-btn :disabled="getterGetIsSendingDeviceConfig" icon @click="close_device_config_dialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <!-- Contents below -->
      <!-- Recreate/mount the contents every time dialog opens/closes with v-if -->
      <!-- This should re-initialize/recreate all child components -->
      <v-layout v-if="deviceConfigDialog" style="display:block">
        <!-- v-tabs-items is flexbox by default so "flex-grow-1" utility class is needed to override it's default styling -->
        <v-tabs-items v-model="mainContentTab">
          <v-tab-item>
            
              <div class="main-content-layout" :class="{ 'main-content-help-layout': showHelpPanel }">
  
                <v-slide-x-transition>
                  <div v-show="showSideMenu" ref="sideMenu" class="side-menu grey darken-3">
                    <!-- Side Menu Items -->
                    <!-- This is dynamically generated with object array -->
                    <!-- height is 80% for side menu items and 20% for button container -->
                    <v-list class="sidemenu-container grey darken-3" style="padding: 10px 0" dark shaped width="250">
                      <!-- keep the first menu group open initially with ":value" binding -->
                      <v-list-group v-for="(mainItem, index) in sideMenu" :key="index" :prepend-icon="mainItem.icon" :value="index === 0 ? true : false" color="blue lighten-3" sub-group>
                        <template v-slot:activator>
                          <v-list-item-content>
                            <v-list-item-title>{{ mainItem.title }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                        <v-list-item v-for="(subItem, index) in mainItem.subMenu" :key="index" link dense :class="{ 'active-config-menu' : isMenuActive(subItem.id) }" @click="selectMenuItem(subItem.id)">                          
                          <v-list-item-icon>
                            <!-- transtion group is used for fade in and fade out animation of the icons -->
                            <!-- name="fade" will have the transiation-group look for all classes that start with "fade" and bind it to the children components -->
                            <!-- where the transition animation will be applied -->
                            <transition-group name="fade" tag="div" class="icon-container ml-1">
                              <v-icon v-if="subItem.valid" key="valid-icon" small v-text="subItem.icon"></v-icon>
                              <v-icon v-else key="alert-icon" small color="red">mdi-alert-circle</v-icon>
                            </transition-group>
                          </v-list-item-icon>
                          <v-list-item-title v-text="subItem.title"></v-list-item-title>
                        </v-list-item>
                      </v-list-group>
                    </v-list>
                    <div class="button-container d-flex flex-column align-center" style="gap: 1rem;">
                      <v-btn small dark color="red darken-1" :disabled="getErrorCount === 0" @click.stop="open_error_list_panel">
                        <v-icon class="mr-2">mdi-alert-circle-outline</v-icon>
                        {{ getErrorCount }} error(s) remaining
                      </v-btn>
                      <v-btn small dark @click="submit_config" :disabled="!isAllFormValid">Preview and Submit</v-btn>
                    </div>
                  </div>
                </v-slide-x-transition>
      
                <!-- Main content configuration forms -->
                <div>
                  <div v-for="(component, index) in mainContent" :key="index" :id="component.id">
                    <!-- dynamically render child components -->
                    <!-- Note that ref is bound to a method, which will collect all child component references in an array for all in one child component validation -->
                    <transition-group name="formfade">
                      <component v-show="currentItemId === component.id" class="config-forms" :is="component.name" :ref="setFormReferences" :id="component.id" @changeValidState="changeValidState($event)" :key="component.id" />
                    </transition-group>
                  </div>
                </div>
  
                <!-- explanation panel -->
                <ConfigHelpPanel :showHelpPanel="showHelpPanel" :currentItemId="currentItemId" />
              </div>
          </v-tab-item>

          <!-- Preview Tab -->
          <v-tab-item>
            <ConfigPreview :singleRouterInfo="singleRouterInfo" :isGroupConfig="isGroupConfig" @return="mainContentTab = 0" @close="confirm_close_device_config_dialog"/>
          </v-tab-item>
        </v-tabs-items>
      </v-layout>

    </v-card>

    <v-slide-y-reverse-transition>
      <div v-if="checkScreenSize()" class="config-footer pa-4">
        <v-btn small dark @click="submit_config" :disabled="!isAllFormValid">Preview and Submit</v-btn>
        <v-btn small dark color="red red darken-1" :disabled="getErrorCount === 0" @click.stop="open_error_list_panel">
          <v-icon class="mr-2">mdi-alert-circle-outline</v-icon>
          {{ getErrorCount }} error(s) remaining
        </v-btn>
      </div>
    </v-slide-y-reverse-transition>

    <!--Sub Dialogs -->
    <ConfigChangeHistoryPanel :configChangeHistoryPanel="configChangeHistoryPanel" @close="close_config_change_history_panel" />
    <ErrorListPanel :errorListPanel="errorListPanel" @close="close_error_list_panel" />
    <ConfigDiscardDialog :configDiscardDialog="configDiscardDialog" @close="confirm_close_device_config_dialog" />
  </v-dialog>
</template>

<script>
// RG2100 forms
import RG2100_MainWifi from "./DeviceConfigurationComponents/RG2100/MainWiFi.vue"
import RG2100_GuestWifi from "./DeviceConfigurationComponents/RG2100/GuestWiFi.vue"
import RG2100_Connections from "./DeviceConfigurationComponents/RG2100/Connections.vue"
import RG2100_Display from "./DeviceConfigurationComponents/RG2100/Display.vue"
import RG2100_Control from "./DeviceConfigurationComponents/RG2100/Control.vue"
import RG2100_DataUsage from "./DeviceConfigurationComponents/RG2100/DataUsage.vue"
import RG2100_DHCPRange from "./DeviceConfigurationComponents/RG2100/DHCPRange.vue"
import RG2100_BasicSecurity from "./DeviceConfigurationComponents/RG2100/BasicSecurity.vue"
import RG2100_MACFiltering from "./DeviceConfigurationComponents/RG2100/MACFiltering.vue"
import RG2100_TODAccess from "./DeviceConfigurationComponents/RG2100/TODAccess.vue"

// FX20
import FX20_WirelessLAN from "./DeviceConfigurationComponents/FX20/WirelessLAN.vue"
import FX20_VPNPassthrough from "./DeviceConfigurationComponents/FX20/VPNPassthrough.vue"
import FX20_ParentalControl from "./DeviceConfigurationComponents/FX20/ParentalControl.vue"
// import FX20_DMZ from "./DeviceConfigurationComponents/FX20/DMZ.vue"
// import FX20_DDNS from "./DeviceConfigurationComponents/FX20/DDNS.vue"
// import FX20_DHCPRange from "./DeviceConfigurationComponents/FX20/DHCPRange.vue"
// import FX20_PortForwarding from "./DeviceConfigurationComponents/FX20/PortForwarding.vue"
// import FX20_ContentFiltering from "./DeviceConfigurationComponents/FX20/ContentFiltering.vue"
// import FX20_URLFiltering from "./DeviceConfigurationComponents/FX20/URLFiltering.vue"
// import FX20_ServiceFiltering from "./DeviceConfigurationComponents/FX20/ServiceFiltering.vue"
// import FX20_MACFiltering from "./DeviceConfigurationComponents/FX20/MACFiltering.vue"
// import FX20_NTPTime from "./DeviceConfigurationComponents/FX20/NTPTime.vue"
// import FX20_System from "./DeviceConfigurationComponents/FX20/System.vue"
// import FX20_RouterAdmin from "./DeviceConfigurationComponents/FX20/RouterAdmin.vue"
// import FX20_ReportSets from "./DeviceConfigurationComponents/FX20/ReportSets.vue"
// import FX20_AlertsSets from "./DeviceConfigurationComponents/FX20/AlertsSets.vue"

// history panel
import ConfigChangeHistoryPanel from "./ConfigHistoryPanel/ConfigChangeHistoryPanel.vue"
// help panel
import ConfigHelpPanel from "./ConfigHelpPanel/ConfigHelpPanel.vue"
// error list panel
import ErrorListPanel from "./ErrorListPanel/ErrorListPanel.vue"
// preview
import ConfigPreview from "./ConfigPreview/ConfigPreview.vue"

import ConfigDiscardDialog from "./ConfigurationSubDialogs/ConfigDiscardDialog.vue"

import { mixin_device_model_menu_utils } from "@/mixins/deviceModelMenuUtils.js"
import { mapGetters, mapMutations } from "vuex";

export default {
  mixins: [ mixin_device_model_menu_utils ],
  components: 
  {
    // RG2100 Forms
    RG2100_MainWifi,
    RG2100_GuestWifi,
    RG2100_Connections,
    RG2100_Display,
    RG2100_Control,
    RG2100_DataUsage,
    RG2100_DHCPRange,
    RG2100_BasicSecurity,
    RG2100_MACFiltering,
    RG2100_TODAccess,

    // FX20 Forms
    FX20_WirelessLAN,
    FX20_VPNPassthrough,
    FX20_ParentalControl,
    // FX20_DMZ,
    // FX20_DDNS,
    // FX20_DHCPRange,
    // FX20_PortForwarding,
    // FX20_ContentFiltering,
    // FX20_URLFiltering,
    // FX20_ServiceFiltering,
    // FX20_MACFiltering,
    // FX20_NTPTime,
    // FX20_System,
    // FX20_RouterAdmin,
    // FX20_ReportSets,
    // FX20_AlertsSets,

    // Dialogs
    ConfigChangeHistoryPanel,
    ErrorListPanel,
    ConfigHelpPanel,
    ConfigPreview,
    ConfigDiscardDialog
  },
  props: {
    deviceConfigDialog: Boolean,
    isGroupConfig: Boolean,
    singleRouterInfo: Object,
  },
  data() {
    return {
      selectedMainItem: "",
      mainContentTab: 0,
      // Initiate the page by setting currently active menu to mainWifi. This is temporary and should be changed to accomodate other models.
      currentItemId: "",

      // Show/hide side menu
      showSideMenu: true,
      // List of side menu items. The side menu is dynamically generated from this list up to the submenus
      sideMenu: [],
      // child components inside the carousel that will be dynamically rendered
      mainContent: [],
      // This a list of reference objects for dynamic form validation
      // The list will be filled in automatically at the time of DOM generation via v-for loop and setFormReferences method
      mainContentReferenceList: [],
      isAllFormValid: true,
      
      // This is variable for history change panel
      configChangeHistoryPanel: false,
      errorListPanel: false,
      configDiscardDialog: false,

      // Show/hide help panel
      showHelpPanel: false,
    };
  },
  // add or remove evenlistener for responsive side menu
  mounted() {
    this.sideMenuResize();
    window.addEventListener('resize', this.sideMenuResize);

    document.addEventListener('click', this.sideMenuClickOutside);
  },
  beforeDestroy() {
    if (typeof window === 'undefined') return;
    window.removeEventListener('resize', this.sideMenuResize);

    document.removeEventListener('click', this.sideMenuClickOutside);
  },
  watch: {
    // reinitialize to default values everytime the dialog closes down.
    // mainContentReferenceList must be emptied out due to <v-layout v-if="deviceConfigDialog"> constantly remounting on dialog open
    // all menu selection related items must also be reset
    deviceConfigDialog: {
      handler(newVal){
        if(!newVal) {
          this.mainContentReferenceList = [];
          this.mainContentTab = 0;
          this.configChangeHistoryPanel = false;
          // reset vuex configs and change history
          this.mutationResetDeviceConfigurationDiff();
          // reset all form validation states
          this.mutationResetFormValidation();
          // reset error list
          this.mutationResetErrors();
          // reset current device model to null
          this.mutationSetCurrentDeviceModel(null);
          this.sideMenu = [];
          this.mainContent = [];
        } else {
          this.renderModelComponents();
          // Reset form validation as the dialog is opened again
          this.isAllFormValid = this.getterFormIsAllValid;
        }
      },
      immediate: true
    },
    
    getterGetCurrentError: {
      handler(newVal){
        if(newVal.path){
          let errorMenu = newVal.path.split('.')[0];
          this.$nextTick(() => {
            this.currentItemId = errorMenu;
          });
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapGetters(["getterGetConfigurationsDiff", "getterFormIsAllValid", "getterGetIsSendingDeviceConfig", "getterGetErrorList", "getterGetCurrentError"]),
    getErrorCount(){
      return this.getterGetErrorList.length;
    }
  },
  methods: {
    ...mapMutations(["mutationSetCurrentDeviceModel", "mutationResetDeviceConfigurationDiff", "mutationResetFormValidation", "mutationSetSnackBarItem", "mutationResetErrors", "mutationSetCurrentDeviceModelAlias"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    // Set the current model that has been selected to render appropriate side menu and components
    renderModelComponents(){
      // Note that since models such as RG2100 and RG2102 share the exact same data tables and files
      // the "getterGetCurrentDeviceModel" will be set to RG2100 if RG2102 is detected.
      // It's real model name "RG2102" will be stored in "getterGetCurrentDeviceModelAlias"
      // This will be the same for all devices that share same files or local data structures
      let modelAliasTable = {
        RG2100: "RG2100",
        RG2102: "RG2100",
        FX20: "FX20"
      }
      // Get the current model. Check if the current dialog instance is for Group configs or single device configs
      let realModelName = this.isGroupConfig ? this.singleRouterInfo.group_model : this.singleRouterInfo.router_model;
      let currentModel = modelAliasTable[realModelName];
      // Set the real name as Alias first in vuex
      this.mutationSetCurrentDeviceModelAlias(realModelName);
      // Set the model string in vuex. This will be referenced in both History Panel, Config Preview, and Help Panel
      this.mutationSetCurrentDeviceModel(currentModel);
      // Choose side menu and main content structure for rendering UI from deviceModelMenuUtils.js
      this.sideMenu = [ ...this.mixinSideMenu[currentModel] ];
      this.mainContent = [ ...this.mixinMainContent[currentModel] ];
      // Set the current item to the very first item of the content structure
      // This will make sure page always starts at the first item
      this.currentItemId = this.mixinMainContent[currentModel][0].id;
    },
    // set main content from menu selection
    selectMenuItem(id) {
      if(this.mainContent.some((item) => item.id === id)) {
        this.currentItemId = id;
      }
    },
    // check if a menu is active or not by checking the id. If a menu is active, corresponding one will be highlighted on the UI
    isMenuActive(id){
      return id === this.currentItemId;
    },
    // check for mobile or desktop orientation 1000px wide
    checkScreenSize(){
      return window.innerWidth < 1000;
    },
    // Side menu appears/disappears depending on screen size
    sideMenuResize() {
      this.checkScreenSize() ? this.showSideMenu = false :  this.showSideMenu = true;
    },
    // Close down side menu if the user clicks anywhere outside of menu
    sideMenuClickOutside(event){
      const sideMenu = this.$refs.sideMenu;
      if (this.checkScreenSize() && this.showSideMenu && sideMenu && !sideMenu.contains(event.target)) {
        this.showSideMenu = false;
      }
    },
    // open/close side menu on btn click and close down help panel if it is open
    toggle_side_menu() {
      this.showHelpPanel= false;
      this.showSideMenu = !this.showSideMenu;
    },
    // vue automatically passes a reference object as a parameter when this method is bound to ":ref" property.
    // this method will collect array of form component objects for easy form validation.
    // componentRef = child component referent객체
    // avoid refs from being turned into null references at the time of unmounting and also avoid ref duplicate on remounting
    // ref duplication and ref null should not occur due to mainContentReferenceList being emptied out when the dialog is closed
    // however, the if logic is still here just in case
    setFormReferences(componentRef) {
      if (componentRef && !this.mainContentReferenceList.includes(componentRef)) {
        this.mainContentReferenceList.push(componentRef);
      }
    },
    // Set validity state of all menu items
    // All child forms should this.$emit('changeValidState', { id, valid }) when of the fields change
    changeValidState(stateObj) {
      this.sideMenu.forEach(menu => {
        menu.subMenu.forEach(subMenu => {
          if(subMenu.id === stateObj.id) {
            subMenu.valid = stateObj.valid;
          }
        })
      })
      // Check validity of all forms to change the state of the "Submit" button
      // If one or more forms should fail, the "Submit" button will be disabled
      // ref="form" must be present for all child <v-form> tags for validation algorithm to work correctly
      // this.isAllFormValid = this.mainContentReferenceList.every(form => form.mixinValidateForm());
      // console.log("From DeviceConfigDialog.vue");
      // console.log("Form validation states: ");
      // console.log(this.$store.getters.getterGetValidationStates);
      // if(!this.getterFormIsAllValid) console.log(this.getterFormIsAllValid);
      this.isAllFormValid = this.getterFormIsAllValid;
    },
    // Toggle help panel
    // Manually closing down side menu is not required for this button, as side menu will close down if the user clicks anywhere outside of side menu
    toggle_help_panel() {
      this.showHelpPanel = !this.showHelpPanel;
    },
    // Show/Hide history panel
    toggle_config_change_history_panel() {
      this.configChangeHistoryPanel = !this.configChangeHistoryPanel;
    },
    close_config_change_history_panel() {
      this.configChangeHistoryPanel = false;
    },
    open_error_list_panel() {
      this.errorListPanel = true;
    },
    close_error_list_panel() {
      this.errorListPanel = false;
    },
    errorListPanelClickOutside(event){
      const errorListPanel = this.$refs.errorListPanel;
      if (this.errorListPanel && errorListPanel && !errorListPanel.contains(event.target)) {
        this.errorListPanel = false;
      }
    },
    // Send changed configuration to the backend server
    submit_config() {
      if(this.getterGetConfigurationsDiff.length === 0) {
        this.triggerSnackBar("error", "At least make one configuration change before submission");
        return;
      }
      this.mainContentTab = 1;
    },
    // close the configuration dialog
    // if there are any changes to the configuration, ask if the user is sure that they want to discard everything
    close_device_config_dialog() {
      if(this.getterGetConfigurationsDiff.length > 0) {
        this.configDiscardDialog = true;
      } else {
        this.$emit("close");
      }
    },
    // if the user click on "confirm", close down the configuration dialog
    // else, just close down the "are you sure" window
    confirm_close_device_config_dialog(discardVal) {
      if(discardVal === 1) {
        this.configDiscardDialog = false;
        this.$emit("close");
      } else {
        this.configDiscardDialog = false;
      }
    }
  },
};
</script>

<style scoped>
/* -------------Dialog Layout related------------- */
/* Main Layout with toolbar and main content */
.main-layout {
  --appbar-height: 64px;
  --main-content-height: calc(100vh - var(--appbar-height));

  display: grid;
  grid-template-rows: var(--appbar-height) 1fr;
}
/* Side menu and content layout(Header excluded) */
.main-content-layout {
  display: grid;
  position: relative;
  height: var(--main-content-height);
}
/* Note that the height needs to be set this way to prevent the whole dialog from scrolling */
/* If any overflow happens in y direction, then the content itself will scroll only, not the whole dialog */
.config-forms {
  height: var(--main-content-height);
  overflow-y: auto;
}

/* ---------------Side Menu related-------------- */
/* remove icon rotation from side menu */
.side-menu {
  position: absolute;
  height: var(--main-content-height);
  z-index: 9999;
}
::v-deep .v-list-group--active .v-icon {
  transform: none !important;
}
/* add absolute positioning to the icons so that they overlap in one place */
.icon-container {
  position: relative;
}
.icon-container > * {
  position: absolute;
}
/* Sidemenu v-list styling */
.sidemenu-container {
  overflow-y: auto;
  height: 80%;
}
/* Sidemenu active-button styling */
.active-config-menu {
  background-color: rgba(255, 255, 255, 0.1);
}
/* submit button container styling */
.button-container {
  height: 20%;
  padding: 1rem;
}

.config-dialog-title {
  font-size: 1rem;
}

/* Config Footer Buttons */
.config-footer {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  background-color: #525252;
  bottom: 0;
}


/* validation text styling */
.all-form-validation-text {
  max-width: 200px;
  word-break: break-word;
}

@media (min-width: 1000px) {
  .config-dialog-title {
    font-size: 1.2rem;
  }
  .main-content-layout {
    grid-template-columns: 250px auto;
  }
  .main-content-help-layout {
    grid-template-columns: 250px auto 300px;
  }
  .side-menu {
    position: static;
  }
}

@media (max-width: 1000px) {
  .config-forms {
    height: calc(var(--main-content-height) - 60px);
  }
}

.error-button-glow {
  font-size: 80px;
  color: #fff;
  text-align: center;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 1px #fff, 0 0 4px #fff, 0 0 7px #ecafce, 0 0 10px #ecafce, 0 0 13px #ecafce, 0 0 17px #ecafce, 0 0 20px #ecafce;
  }
  to {
    text-shadow: 0 0 2px #fff, 0 0 5px #ff4da6, 0 0 8px #ff4da6, 0 0 11px #ff4da6, 0 0 14px #ff4da6, 0 0 18px #ff4da6, 0 0 21px #ff4da6;
  }
}
</style>
