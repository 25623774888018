<template>
  <v-card>
    <v-card-text>
      <v-card-text>
        <v-form ref="form">
          <p class="text-center font-weight-light mb-2">Change Temporary Password</p>

          <div class="mb-3 text-caption-2">*Please enter a new password for your account</div>
          <v-text-field outlined filled dense :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword" label="Password" v-model="newPassword" :rules="mixinLoginPasswordRule()" @keydown.space.prevent @keydown.enter.prevent="set_new_password()"></v-text-field>

          <v-text-field outlined filled dense :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword" label="Confirm Password" v-model="confirmNewPassword" :rules="mixinLoginPasswordRule()" @keydown.space.prevent @keydown.enter.prevent="set_new_password()"></v-text-field>
        </v-form>
      </v-card-text>
    </v-card-text>

    <v-card-actions class="d-flex justify-center mb-0 pb-0">
      <v-btn text color="#64B5F6" :loading="loading" @click="back()">Back</v-btn>

      <v-spacer></v-spacer>
      <v-btn text depressed color="#64B5F6" :loading="loading" @click="set_new_password()">Next</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mixin_form_validation_utils } from "@/mixins/formValidationUtils";
import { mapActions, mapGetters, mapMutations } from "vuex";

import { completeNewPassword } from "@/services";
import { utilHandleExceptions } from "@/utils";

export default {
  mixins: [mixin_form_validation_utils],
  data() {
    return {
      loading: false,
      newPassword: "",
      confirmNewPassword: "",

      showPassword: false,
    };
  },
  computed: {
    ...mapGetters(["getterGetWindowPage", "getterGetLoginInfo"]),
    windowPage: {
      get() {
        return this.getterGetWindowPage;
      },
      set(value) {
        this.mutationSetWindowPage(value);
      },
    },
    loginInfo: {
      get() {
        return this.getterGetLoginInfo;
      },
      set(value) {
        this.mutationSetLoginInfo(value);
      },
    },
  },
  methods: {
    ...mapActions(["actionResetLoginInfo"]),
    ...mapMutations(["mutationSetWindowPage", "mutationSetLoginInfo", "mutationSetSnackBarItem"]),
    triggerSnackBar(type, text) {
      this.mutationSetSnackBarItem({ type, text });
    },
    back() {
      this.windowPage = "Login";
    },
    async set_new_password() {
      if(!this.$refs.form.validate()) return this.triggerSnackBar("error", "Enter all the mandatory fields.");
      if (this.newPassword !== this.confirmNewPassword) return this.triggerSnackBar("error", "The passwords do not match.");

      try {
        this.loading = true;
        await completeNewPassword(this.loginInfo, this.newPassword);
        await this.actionGetUserInfo(this.userName.toLowerCase());
        this.actionResetLoginInfo();
        this.triggerSnackBar("success", "Welcome");
      } catch (error) {
        this.triggerSnackBar("error", utilHandleExceptions(error));
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
